import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType } from '@/types.d'
import { Gutenberg } from '@/components/Gutenberg'
import AlphabetList from '@/components/AlphabetList'

interface MemberCompany {
  title: string
  url: string
}
interface DistrictsData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      seo: SeoType
      image: any
      blocks: any
      blocksJSON: any
      heroFilter: boolean
    }
    memberCompany: {
      nodes: Array<MemberCompany>
    }
    menu?: any
    quickmenu?: any
  }
}

const DistrictTemplate = ({ data }: DistrictsData) => {
  // Page data
  const { page, memberCompany } = data
  const { menu, quickmenu } = data

  const heroContent = {
    image: page?.image,
    disableBackButton: true,
    filter: page?.heroFilter,
  }

  return (
    <Layout
      title={page?.title}
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      uri={page?.uri}
      seo={page?.seo}
      useBreadCrump={true}
    >
      <div className="mx-auto max-w-[1100px]">
        <Gutenberg blocks={page?.blocks} />
      </div>

      <div className="mx-auto max-w-[1100px]">
        <AlphabetList entries={memberCompany} />
      </div>
    </Layout>
  )
}

export const PageQuery = graphql`
  query DisrictPageById($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      uri
      heroFilter: gwShowPostHeaderFilter
      ...pageBlocks
      ...seo
      ...featuredHeroImage
    }
    memberCompany: allWpGwRemoteItem(sort: { fields: title, order: ASC }) {
      nodes {
        title
        url: gwRemoteItemRemoteLink
      }
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default DistrictTemplate
